import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop: FC<Props> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  return <>{children}</>;
};

interface Props {
  children: ReactNode;
}

export default ScrollToTop;
